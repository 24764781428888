import React, { useContext } from 'react';
import { ThemeContext } from '@contexts/ThemeContext';
import ImgGraph1 from '@images/main/graph-01.png';
import ImgGraph1D from '@images/main/graph-01_D.png';
import ImgGraph2 from '@images/main/graph-02.png';
import ImgGraph2D from '@images/main/graph-02_D.png';
import ImgGraph3 from '@images/main/graph-03.png';
import ImgGraph3D from '@images/main/graph-03_D.png';
import ImgGraph4 from '@images/main/graph-04.png';
import ImgGraph4D from '@images/main/graph-04_D.png';

import './style.scss';

const Why = () => {
  const { themeState } = useContext(ThemeContext);
  const { isDarkMode } = themeState;

  return (
    <section id="why">
      <div className="container">
        <div className="row">
          <div className="why">
            <h2>왜 필요할까요?</h2>
            <p className="description">
              허브스팟 통계를 보면 신규고객 유치도 중요하지만 기존고객 유치가 더 중요합니다
              <br />
              멤버십 솔루션을 통해 우리 매장의 고정 고객을 늘리는데 도움을 드리겠습니다
            </p>
            <ul>
              <li>
                <img src={isDarkMode ? ImgGraph1D : ImgGraph1} alt="고객 재구매율" />
                <div>
                  <h3>고객 재구매율</h3>
                  <p>고객 재구매율을 5%만 올려도 이익이 95% 상승합니다.</p>
                </div>
              </li>
              <li>
                <img src={isDarkMode ? ImgGraph2D : ImgGraph2} alt="구매 전환율" />
                <div>
                  <h3>구매 전환율</h3>
                  <p>
                    멤버십 고객 구매 전환율은 신규 고객의 구매 전환율보다 3배에서 최대 14배 차이가 납니다. 멤버십 고객을
                    유치해야하는 이유입니다.
                  </p>
                </div>
              </li>
              <li>
                <img src={isDarkMode ? ImgGraph3D : ImgGraph3} alt="고객 유치를 위한 마케팅 투자 비용" />
                <div>
                  <h3>고객 유치를 위한 마케팅 투자 비용</h3>
                  <p>
                    신규 고객을 유치하는 마케팅 비용은 멤버십 고객들의 재구매를 일으키게하는 마케팅 비용보다 6~7배나 더
                    비싼 비용이 들어갑니다. 적은 비용으로 멤버십 고객들의 재구매율을 높여 더 많은 이익이 창출됩니다.
                  </p>
                </div>
              </li>
              <li>
                <img src={isDarkMode ? ImgGraph4D : ImgGraph4} alt="고객의 브랜드 우선순위" />
                <div>
                  <h3>고객의 브랜드 우선순위</h3>
                  <p>83%의 고객은 타 브랜드보다 멤버십 브랜드를 우선순위에 둡니다.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
