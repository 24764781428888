import React from 'react';
import Seo from '@components/common/Seo';
import CvveSection from '@components/Home/sections/CvveSection';

import BusinessSection from '@components/Home/sections/BusinessSection';
import IntroSection from '@components/Home/sections/IntroSection';
import KakaoSection from '@components/Home/sections/KakaoSection';
import SolutionSection from '@components/Home/sections/SolutionSection';
import StrengthSection from '@components/Home/sections/StrengthSection';
import Unit1Section from '@components/Home/sections/Unit1Section';
import WhySection from '@components/Home/sections/WhySection';
import Layout from '@layouts/Layout';
import HeroImageSection from '@components/common/sections/HeroImageSection';
import SolutionSummarySection from '@components/common/sections/SolutionSummarySection';


const Home = () => {
  return (
    <Layout>
      <CvveSection />
      {/* <HeroImageSection />
      <IconAnimationSection />
      <SolutionSummarySection /> */}
      <HeroImageSection />
      <SolutionSummarySection />

      {/* <IntroSection /> */}
      <WhySection />
      <StrengthSection />
      <KakaoSection />
      <SolutionSection />
      <BusinessSection />
      <Unit1Section />
    </Layout>
  );
};

export default Home;

export const Head = () => {
  return <Seo />;
};
