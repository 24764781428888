import React from 'react';
import './style.scss';

const Unit1 = () => {
  return (
    <section id="unit1">
      <div className="container">
        <div className="row">
          <div className="unit1">
            <h2>Unit1</h2>
            <p>
              비대면 시대 필수 요소인 1초 간편결제와 정기결제 솔루션 <br />
              카카오톡 및 앱 기반의 수발주, 멤버십, 스마트 오더 솔루션을 제공하고 있습니다. <br />
              <br />그 외 매장 운영 시 문제점도 언제든지 공유해 주세요.
            </p>
            <a href='http://pf.kakao.com/_NJtGG/chat' target="_blank">
              <button className="unit1KakaoChatBtn">카카오 채널 채팅문의</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Unit1;
