import React, { useContext } from 'react';
import { ThemeContext } from '@contexts/ThemeContext';
import ImgStrength from '@images/main/strength.png';
import ImgStrengthD from '@images/main/strength_D.png';
import './style.scss';

const Strength = () => {
  const { themeState } = useContext(ThemeContext);
  const { isDarkMode } = themeState;

  return (
    <section id="strength">
      <div className="container">
        <div className="row">
          <div className="strength">
            <h2>Strength</h2>
            <div className="content">
              <h3>우리의 솔루션을 직접 사용하고 직접 느낍니다</h3>
              <img src={isDarkMode ? ImgStrengthD : ImgStrength} alt="솔루션 개발, 직접 테스트" />
              <p>
                DGMIT UNIT1은 솔루션 개발만 하는 것이 아닌 해당 솔루션을 이용한 서비스를 DGMIT에서 운영하는 직영 매장에
                가장 만저 적용하고 테스트하며 현장 담당자와 고객의 피드백을 실시간으로 받아 실질적으로 필요한 솔루션과
                서비스의 개선&middot;성장을 위해 지속하고 있습니다
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Strength;
