import React, { useContext } from 'react';
import { ThemeContext } from '@contexts/ThemeContext';
import ImgBusiness1 from '@images/main/business-01.png'
import ImgBusiness1D from '@images/main/business-01_D.png'
import ImgBusiness2 from '@images/main/business-02.png'
import ImgBusiness2D from '@images/main/business-02_D.png'
import ImgBusiness3 from '@images/main/business-03.png'
import ImgBusiness3D from '@images/main/business-03_D.png'
import ImgBusiness4 from '@images/main/business-04.png'
import ImgBusiness4D from '@images/main/business-04_D.png'
import ImgBusiness5 from '@images/main/business-05.png'
import ImgBusiness5D from '@images/main/business-05_D.png'
import './style.scss';

const Business = () => {
  const { themeState } = useContext(ThemeContext);
  const { isDarkMode } = themeState;

  return (
    <section id="business" className="business-wrapper">
      <div className="container">
        <div className="row">
          <div className="business">
            <h2>Business</h2>
            <div className="content">
              <p>
                10여년 이상의 다양한 프로젝트 컨설팅 경험을 바탕으로 수발주, 주문 및 결제, 멤버십 솔루션을 O2O 및
                온라인에 최적화하여 제공합니다
              </p>
              <ul className="business-list">
                <li>
                  <img src={isDarkMode ? ImgBusiness1D : ImgBusiness1} alt="간편 결제 및 정기결제(구독) 솔루션" />
                  <h3>
                    간편 결제 및<br />
                    정기결제(구독) 솔루션
                  </h3>
                </li>
                <li>
                  <img src={isDarkMode ? ImgBusiness2D : ImgBusiness2} alt="O2O 포인트 및 스마트 오더 솔루션" />
                  <h3>
                    O2O 포인트 및<br />
                    스마트 오더 솔루션
                  </h3>
                </li>
                <li>
                  <img src={isDarkMode ? ImgBusiness3D : ImgBusiness3} alt="출입통제 및 출입명부생성 솔루션" />
                  <h3>
                    출입통제 및<br />
                    출입명부생성 솔루션
                  </h3>
                </li>
                <li>
                  <img src={isDarkMode ? ImgBusiness4D : ImgBusiness4} alt="클라우드 플랫폼 제공 및 운영유지보수" />
                  <h3>
                    클라우드 플랫폼 제공 및<br />
                    운영유지보수
                  </h3>
                </li>
                <li>
                  <img src={isDarkMode ? ImgBusiness5D : ImgBusiness5} alt="SI 자문 및 개발 제공" />
                  <h3>
                    SI 자문 및<br />
                    개발 제공
                  </h3>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Business;
