import React from 'react';
import ImgHomeMainSolution from '@images/main/home-main-solution.png';
import './HeroImageSection.scss';



const HeroImageSection = () => {
  return (
    <div id="HeroImageWrapper">
      <div className="hero-image-wrapper">
        <img id="logo" src={ImgHomeMainSolution} alt="cvveLogo" />
      </div>
      <div className="glow"></div>
    </div>
  );
};

export default HeroImageSection;
