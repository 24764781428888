import React from 'react';
import './style.scss';

const Kakao = () => {
  return (
    <section id="kakao">
      <div className="container">
        <div className="row">
          <div className="kakao">
            <p>
              앱 설치 없이 적립하고 <strong>카카오톡</strong> 기반으로 쉽게 멥버십 확인하고 주문까지 한번에<br/>
              배달정산&middot;매출정보 확인&middot;수발주 관리까지 이 모든 기능을 하나의 프로그램으로 관리하세요
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Kakao;
