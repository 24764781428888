import React from 'react';
import './style.scss';

const Cvve = () => {
  return (
    <section id="cvve">
      <div className="container">
        <div className="row">
          <div className="cvve pdf-link">
            <p>
              앞서나가는 프랜차이즈의 비결<br/>
              <span>'프랜차이즈 통합 관리 솔루션, 프비티'</span>
            </p>
            <a href="https://fbiti.com/" target='_blank' className="pdf-btn">
              <em>프비티 소개페이지 보기</em>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cvve;
