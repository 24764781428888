import React from 'react';
import './style.scss';

const Solution = () => {
  return (
    <section id="solution">
      <div className="container">
        <div className="row">
          <div className="solution">
            <h2>Solution</h2>
            <ul className="card-list">
              <li className="card">
                <h3>
                  통합 솔루션
                  <br />
                  프비티 Cloud
                </h3>
                <p>
                  한 개의 주문 및 결제 화면에서 가맹매장 각각의 결제정산이 자동으로 이루어지는 서비스로 데이터는 본사로
                  통합되고 결제 대금은 매장으로 자동 정산
                </p>
                <p>각 매장의 영세율을 반영하여 최저 결제 수수료를 실현하는 솔루션</p>
                <ul>
                  <li>빠른 주문(현장/픽업)과 결제를 제공하는 스마트 오더</li>
                  <li>본사 고객정보 획득 및 관리 제공</li>
                  <li>매장 고객정보 제한적 노출을 통한 개인정보 보호</li>
                  <li>쿠폰/이벤트/모바일 상품권 운영</li>
                  <li>업체 최초 OPMSP(One Page Multi Store Payment) 제공</li>
                  <li>카카오톡 기반의 멤버십 서비스 (카카오 채널 연동)</li>
                  <li>SMS/LMS/카카오톡 기반의 마케팅 메세지</li>
                  <li>프랜차이즈 본사를 위한 포인트 시스템 (매장별 정산)</li>
                  <li>B2B 유통사 및 프랜차이즈 본사를 위한 수발주 시스템</li>
                </ul>
                <p className="pageMoveBtn"><a href="https://fbiti.com/" target="_blank">소개 페이지 이동 →</a></p>
              </li>
              <li className="card">
                <h3>
                  1초 간편결제 및
                  <br />
                  정기결제 솔루션
                </h3>
                <ul>
                  <li>이벤트 기반 결제 (이동/진입 등의 특정 이벤트 발생시 결제)</li>
                  <li>One Page Multi Store Payment 기반의 결제 통합 및 자동정산 제공</li>
                  <li>Mobile/PC/POS/VAN 등 단말 환경 제공</li>
                  <li>원터치 결제/지문 결제/QR 결제</li>
                  <li>정기적으로 결제를 승인하는 정기결제(구독)</li>
                </ul>
              </li>
              <li className="card">
                <h3>
                  소셜체크인 카카오톡 기반
                  <br />
                  출입통제 및 전자출입명부 솔루션
                </h3>
                <ul>
                  <li>출입 데이터 기반 전자출입명부 자동생성 제공</li>
                  <li>전자출입명부(KI-Pass) 포멧 준용</li>
                  <li>별도의 가입이나 인증절차가 필요없는 카카오톡 기반의 출입통제</li>
                  <li>출입통제 기능 제공(원격 출입문 제어 및 인증 출입문 제어)</li>
                </ul>
              </li>
              <li className="card">
                <h3>
                  SI 사업 및
                  <br />
                  스마트 미디어
                </h3>
                <ul>
                  <li>뉴텍상사 업무지원시스템 및 수발주 시스템 외 다수</li>
                  <li>스마트 미디어: 삼성전자/LG전자/KT/SKT/LGT/홈초이스/SKPlanet 등</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solution;
