import React from 'react';
import './SolutionSummarySection.scss';
import ImgLine from '@images/main/정3.png';
import ImgCheckIcon from '@images/main/check-icon-brix-templates.svg';


const SolutionSummarySection = () => {
  return (
    <div id="solutionSummaryWrap">
      <div className="line">
        <img id="solutionLine" src={ImgLine} alt="line" />
      </div>
      <div>
        <div className="card-wrapper">
          <div className="card">
            <div className="card-top">
              <div className="card-name">고객 멤버십</div>
              <div className="card-sub-name">브랜드 단골 고객 양성</div>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>간편 멤버십 가입</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>쿠폰 &amp; 선불카드</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>전자 영수증</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>포인트 교차 정산</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="card">
            <div className="card-top">
              <div className="card-name">수발주 프로그램</div>
              <div className="card-sub-name">효율적인 수발주 ERP</div>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>입출고 관리</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>견적서 관리</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>정산 관리</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>예치금 관리</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-top">
              <div className="card-name">CRM 마케팅</div>
              <div className="card-sub-name">데이터 기반 전략 수립</div>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>고객현황분석</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>기념일 메세지 발송</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>자동화 쿠폰 발송</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>고객 데이터 추출</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-top">
              <div className="card-name">가맹점 영업 관리</div>
              <div className="card-sub-name">효과적인 가맹점 모집</div>
              <div className="card-sub-name info">*2024 상반기 출시 </div>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>가맹 모집 관리</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>문서 및 계약 관리</span>
                  </div>
                </li>
                <li>
                  <span className="card-content-icon">
                    <img id="solutionCheckIcon" src={ImgCheckIcon} alt="checkIcon" />
                  </span>
                  <div className="card-content-text">
                    <span>가맹점 성과 리포트</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionSummarySection;
